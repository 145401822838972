/* Colors */
:root {
  --lightest: 96 71% 99%;
  --darkest: 0 0% 7%;
  --mulberry: 321, 42%, 54%;
  --sky: 195 100% 60%;

  --background: var(--darkest);
  --foreground: var(--lightest);
  --text: var(--foreground);
  --hint: 96 71% 90%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --primary: var(--sky);
  --primary-foreground: 24 9.8% 10%;

  --secondary: var(--mulberry);
  --secondary-foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --accent: 12 6.5% 10%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 30%;
  --input: 12 6.5% 30%;
  --ring: 24 5.7% 82.9%;
}

/* Sizes */
:root {
  --radius: 0.3rem;

  --header-height: 64px;
  --mobile-nav-height: 50px;

  --min-width-mobile: 0px;
  --min-width-tablet: 640px;
  --min-width-laptop: 1024px;
}
