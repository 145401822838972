.layout {
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
  width: 91.666667%;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 640px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
